import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import HeroSection from './HeroSection';
import ConsultPopup from '../../components/Windows/ConsultPopup';
import Statistics from './Statistics';
import VisionMission from './VisionMission';
import ToolsTechnology from './ToolsTechnology';
import CallToAction from './CallToAction';
import FAQs from './FAQs';
import TabsSection from './TabsSection';
import Testimonial from '../About/Testimonial';

gsap.registerPlugin(ScrollTrigger);

function LandingPage() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Setup ScrollTrigger animations
        const sections = gsap.utils.toArray(".animate-on-scroll");

        sections.forEach(section => {
            gsap.fromTo(
                section,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: section,
                        start: "top 80%",
                        end: "bottom top",
                        toggleActions: "play none none reverse",
                    }
                }
            );
        });

        // Cleanup function
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div>
            <HeroSection />
            <div className="animate-on-scroll">
                <TabsSection />
            </div>
            <div className="animate-on-scroll">
                <Statistics />
            </div>
            <div className="animate-on-scroll">
                <VisionMission />
            </div>
            <div className="animate-on-scroll">
                <ToolsTechnology />
            </div>
            <div className="animate-on-scroll">
                <CallToAction />
            </div>
            <div className="animate-on-scroll">
                <FAQs />
            </div>

            <div className="animate-on-scroll">
                <Testimonial />
            </div>
            
            <ConsultPopup isVisible={showModal} onClose={() => setShowModal(false)} />
        </div>
    );
}

export default LandingPage;
