// src/App.js
import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';

// Importing Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import WhatsAppButton from './components/WhatsAppButton';

// Importing Public Website Pages
import LandingPage from './Pages/Home/LandingPage';
import AboutUs from './Pages/About/Page';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';
import Migration from './Pages/Services/Sub-Services/Migration';
import Integration from './Pages/Services/Sub-Services/Integration'
import Consultation from './Pages/Services/Sub-Services/Consultation'
import Support from './Pages/Services/Sub-Services/Support'
import Products from './Pages/Products/Products';
import DMS from './Pages/Products/SubProducts/DMS'
import WebApps from './Pages/Products/SubProducts/WebApps'
import ERPs from './Pages/Products/SubProducts/ERP'
import APIs from './Pages/Products/SubProducts/APIs'

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/services/migration' element={<Migration />} />
        <Route path='/services/Integration' element={<Integration />} />
        <Route path='/services/consultation' element={<Consultation />} />
        <Route path='/services/support' element={<Support />} />
        <Route path='/products' element={<Products />} />
        <Route path='/products/dms' element={<DMS />} />
        <Route path='/products/webApps' element={<WebApps />} />
        <Route path='/products/erps' element={<ERPs />} />
        <Route path='/products/apis' element={<APIs />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
      <WhatsAppButton />
    </>
  );
}

export default App;
