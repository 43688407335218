import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';

function ConsultTabs() {
    const [activeTab, setActiveTab] = useState('bar-with-underline-1');
    const [prevTab, setPrevTab] = useState('bar-with-underline-1');
    const intervalRef = useRef(null);
    const servicesRef = useRef(null);
    const productsRef = useRef(null);

    const handleTabClick = (tabId) => {
        if (tabId !== activeTab) {
            setPrevTab(activeTab);
            setActiveTab(tabId);
        }
    };

    useEffect(() => {
        // Function to switch tabs
        const switchTab = () => {
            setPrevTab(activeTab);
            setActiveTab(prevTab === 'bar-with-underline-1' ? 'bar-with-underline-3' : 'bar-with-underline-1');
        };

        // Set interval to switch tab every 5 seconds
        intervalRef.current = setInterval(switchTab, 15000);

        // Clear interval on component unmount
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [activeTab, prevTab]);

    useEffect(() => {
        if (activeTab === 'bar-with-underline-1' && servicesRef.current) {
            gsap.fromTo(
                servicesRef.current,
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: 0.5 }
            );
        } else if (activeTab === 'bar-with-underline-3' && productsRef.current) {
            gsap.fromTo(
                productsRef.current,
                { opacity: 0, y: 20 },
                { opacity: 1, y: 0, duration: 0.5 }
            );
        }
    }, [activeTab]);

    return (
        <div className='max-w-[80rem] mx-auto'>
            <nav className="relative z-0 flex border rounded-xl overflow-hidden" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
                <button
                    type="button"
                    className={`relative min-w-0 flex-1 logo bg-white first:border-s-0 border-s border-b-2 py-4 px-4 text-gray-500 hover:text-gray-700 text-md font-medium text-center overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none focus:text-blue-600 ${activeTab === 'bar-with-underline-1' ? 'border-b-blue-600 text-gray-900' : ''
                        }`}
                    onClick={() => handleTabClick('bar-with-underline-1')}
                    aria-selected={activeTab === 'bar-with-underline-1'}
                    role="tab"
                >
                    Shared Consultation
                </button>
                <button
                    type="button"
                    className={`relative min-w-0 flex-1 logo text-md bg-white first:border-s-0 border-s border-b-2 py-4 px-4 text-gray-500 hover:text-gray-700 font-medium text-center overflow-hidden hover:bg-gray-50 focus:z-10 focus:outline-none focus:text-blue-600 ${activeTab === 'bar-with-underline-3' ? 'border-b-blue-600 text-gray-900' : ''
                        }`}
                    onClick={() => handleTabClick('bar-with-underline-3')}
                    aria-selected={activeTab === 'bar-with-underline-3'}
                    role="tab"
                >
                    Dedicated Consultation
                </button>
            </nav>

            <div className="mt-3">
                <div id="bar-with-underline-1" role="tabpanel" aria-labelledby="bar-with-underline-item-1" className={activeTab === 'bar-with-underline-1' ? '' : 'hidden'}>
                    <div ref={productsRef} className='mt-12'>
                        <div class="flex flex-wrap -mx-4 items-start">
                            <div class="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
                                <div>
                                    <h1 class="font-light logo text-gray-800 text-5xl mb-4 logo">
                                        <span>Schedule a Shared Consultation</span>
                                    </h1>
                                </div>
                            </div>
                            <div class="w-full lg:w-1/3 xl:w-1/2 px-4">
                                <div class="max-w-sm lg:ml-auto">
                                    <p class="text-lg text-gray-500">Upon submitting a request, it will be placed in a queue. Once your request reaches the front of the queue, it will be assigned to one of our technical experts, who will generate a ticket and work to resolve your technical issue.</p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col mx-auto mt-4">
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Full Name</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Company Name</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Phone</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="dropdown1" class="leading-7 text-sm text-gray-600">Service / Product</label>
                                    <select id="dropdown1" name="dropdown1" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                        <option value="">Select Service / Product</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>

                                <div class="relative w-1/2 mb-4">
                                    <label for="dropdown2" class="leading-7 text-sm text-gray-600">Expected Time</label>
                                    <select id="dropdown2" name="dropdown2" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                        <option value="">Select Expected Time</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </div>
                            <button class="text-white mt-4 bg-blue-500 border-0 py-3 px-6 focus:outline-none hover:bg-blue-600 rounded text-md">
                                Request for Consultation
                            </button>
                            <p class="text-xs text-gray-500 mt-3">Our Team will contact you soon!</p>
                        </div>
                    </div>
                </div>

                <div id="bar-with-underline-3" role="tabpanel" aria-labelledby="bar-with-underline-item-3" className={activeTab === 'bar-with-underline-3' ? '' : 'hidden'}>
                    <div ref={servicesRef} className='mt-12'>
                        <div class="flex flex-wrap -mx-4 items-start">
                            <div class="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
                                <div>
                                    <h1 class="font-light logo text-gray-800 text-5xl mb-4 logo">
                                        <span>Schedule a Dedicated Consultation</span>
                                    </h1>
                                </div>
                            </div>
                            <div class="w-full lg:w-1/3 xl:w-1/2 px-4">
                                <div class="max-w-sm lg:ml-auto">
                                    <p class="text-lg text-gray-500">Upon submitting a request, a dedicated technical expert will be personally assigned to resolve your issue.</p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col mx-auto mt-4">
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Full Name</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Company Name</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                                <div class="relative w-1/2 mb-4">
                                    <label for="email" class="leading-7 text-sm text-gray-600">Phone</label>
                                    <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                </div>
                            </div>
                            <div className='flex space-x-4'>
                                <div class="relative w-1/2 mb-4">
                                    <label for="dropdown1" class="leading-7 text-sm text-gray-600">Service / Product</label>
                                    <select id="dropdown1" name="dropdown1" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                        <option value="">Select Service / Product</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>

                                <div class="relative w-1/2 mb-4">
                                    <label for="dropdown2" class="leading-7 text-sm text-gray-600">Expected Time</label>
                                    <select id="dropdown2" name="dropdown2" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                        <option value="">Select Expected Time</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                    </select>
                                </div>
                            </div>
                            <button class="text-white mt-4 bg-blue-500 border-0 py-3 px-6 focus:outline-none hover:bg-blue-600 rounded text-md">
                                Request for Consultation
                            </button>
                            <p class="text-xs text-gray-500 mt-3">Our Team will contact you soon!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultTabs;
