import React from 'react'

function Contact() {
    return (
        <div>
            <div className="relative overflow-hidden">
                {/* Gradients */}
                <div
                    aria-hidden="true"
                    className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
                >
                    <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
                    <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
                </div>
                {/* End Gradients */}
                <div className="relative z-10">
                    <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                        <div className="max-w-[80rem] text-start mx-auto">
                            <p className="inline-block text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent">
                                BZSoftech: A vision of the future!
                            </p>
                            {/* Title */}
                            <div className="mt-5 max-w-2xl">
                                <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                                    Contact Us
                                </h1>
                            </div>
                            {/* End Title */}
                            <div className="mt-5 max-w-3xl">
                                <p className="text-lg text-gray-600">
                                    At BZ Softech, we’re revolutionizing technology to enhance connectivity and collaboration. Our mission is to drive efficiency and innovation in the digital world. By 2030, we aim to empower 10,000 visionaries to lead the future of software excellence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
