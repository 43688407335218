import React from 'react'
import Wall from '../../assets/wall.png'

function CEOMsg() {
    return (
        <>
            <section
                className="relative max-w-[80rem] mx-auto py-12 px-8 md:py-24 bg-coolGray-50 overflow-hidden">
                <div className="container mx-auto px-4">
                    <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">Message from CEO</span>
                    <h1 class="font-heading mb-4 logo text-5xl xs:text-5xl md:text-6xl font-bold text-gray-900">
                        <span>Take the words</span>
                    </h1>
                    <div>
                        <div className="overflow-hidden">
                            <div
                                className="whitespace-nowrap transition-transform duration-500 ease-in-out"
                                style={{ transform: "translateX(-0%)" }}
                            >
                                <div className="inline-block w-full whitespace-normal mx-2">
                                    <p className="w-full mb-10 text-2xl sm:text-3xl leading-snug">
                                        <span>
                                            It’s hardly possible to reduce costs by means of low rate in modern, dynamic IT. Low rates tend to result in excessive hours spent. We, at Technupur, don’t practice reducing IT service costs by hiring low-paid employees. Instead, we raise productivity, i.e., deliver more in less time. It results in a lower cost of output (new application functionality, stable infrastructure, user issues resolved).
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sm:flex items-center justify-between">
                            <div className="flex mb-10 sm:mb-0 items-center">
                                <img
                                    className="mr-4 h-12 w-12 rounded-full"
                                    src={Wall}
                                    alt="err"
                                />
                                <div>
                                    <span className="block">Owais Majeed</span>
                                    <span className="block text-sm text-coolGray-600">
                                        CEO / Founder
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CEOMsg
