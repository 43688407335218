import React from 'react'
import { Link } from 'react-router-dom'
import mig from '../../../assets/mig.png'

function Migration() {
    return (
        <div>
            <div className="relative overflow-hidden">
                {/* Gradients */}
                <div
                    aria-hidden="true"
                    className="flex absolute -top-96 start-1/2 transform -translate-x-1/2"
                >
                    <div className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem]" />
                    <div className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem]" />
                </div>
                {/* End Gradients */}
                <div className="relative z-10">
                    <div className="max-w-[80rem] mx-auto mt-32 px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                        <div className="max-w-[80rem] text-start mx-auto">
                            <div class="flex items-center flex-wrap gap-4">
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" to="/">Home</Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewbox="0 0 4 8" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z" fill="#19191B"></path>
                                </svg>
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" to="/services">Services</Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewbox="0 0 4 8" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.50225 0.5L0 0.98755L2.95244 4L0.0073503 7.0051L0.50225 7.5L4.00225 4L0.50225 0.5Z" fill="#19191B"></path>
                                </svg>
                                <Link class="inline-block text-sm font-medium tracking-tight bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent" href="/services/migration">Migration</Link>
                            </div>
                            {/* Title */}
                            <div className="mt-5 max-w-2xl">
                                <h1 className="block font-light logo text-gray-800 text-4xl md:text-5xl lg:text-6xl">
                                    Migration
                                </h1>
                            </div>
                            {/* End Title */}
                            <div className="mt-5 max-w-3xl">
                                <p className="text-lg text-gray-600">
                                    At BZ Softech, we’re at the forefront of technology innovation, offering a range of services designed to enhance connectivity, streamline operations, and drive business success. Our expert team delivers tailored solutions that address the specific needs of your organization, ensuring efficiency and growth in a rapidly evolving digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='max-w-7xl mx-auto px-8 my-8'>
                <p className='text-gray-700 text-justify'>
                    Many companies today still work with the same Oracle Forms applications they built years ago despite the advent of the more intuitive Oracle APEX development platform. If your organization still uses a Forms app, there are several reasons why it should be modernized. Although modernization may take some time and effort, the end product is a more user-friendly app that your customers will appreciate, boosting user engagement and revenue.

                    If you’re looking to modernize your Oracle Forms apps, Oracle APEX is the clear platform of choice.

                    In this blog, I’ll show you reasons why you need to migrate your Oracle Forms apps to APEX, as well as how to go about it.
                </p>
            </div>

            <div>
                <img src={mig} alt="er" className=' max-w-7xl mx-auto px-8 w-100' />
            </div>

            <div className=' max-w-7xl mx-auto px-32 my-24 space-y-10'>

                <h1 className='font-bold logo text-gray-800 text-4xl'>Reasons to modernize your Forms App using Oracle APEX</h1>

                <div className='space-y-4'>
                    <h1 className='font-light logo text-gray-800 text-4xl'>1. Enhanced user experience</h1>
                    <p className='text-gray-600 text-justify'>Oracle Forms was released in 1981. Although more recent versions have been created, there is a need to convert these legacy applications to meet modern users’ demands. Oracle APEX apps satisfy this need by being more intuitive, easier to use, highly responsive, and mobile-friendly.</p>
                </div>

                <div className='space-y-4'>
                    <h1 className='font-light logo text-gray-800 text-4xl'>2. Deployable anywhere</h1>
                    <p className='text-gray-600 text-justify'>
                        Unlike Oracle Forms, Oracle APEX can be deployed anywhere, whether on the cloud (including Oracle Cloud), on-premises, or on mobile. Developers and users only need a device and a web browser to build or run an APEX app
                    </p>
                </div>

                <div className='space-y-4'>
                    <h1 className='font-light logo text-gray-800 text-4xl'>3. Portability</h1>
                    <p className='text-gray-600 text-justify'>
                        Unlike Oracle Forms applications, you can install an APEX App on any device. The app integrates seamlessly into the device’s operating system, thereby providing a native app experience. This eliminates the need to build separate app versions for different operating systems, whether mobile or desktop.
                    </p>
                </div>

                <div className='space-y-4'>
                    <h1 className='font-light logo text-gray-800 text-4xl'>5. Scalability</h1>
                    <p className='text-gray-600 text-justify'>
                        Oracle APEX uses SQL and PL/SQL, so neither you nor your Forms development team needs to learn a new programming language to modernize your Forms app.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Migration
