import React from 'react'
import Wall from '../../assets/wall.png'

function Team() {
    return (
        <>
            <section class="relative py-20 md:py-2 overflow-hidden">
                <img class="absolute top-0 left-0" src="saturn-assets/images/team/light-blue.png" alt="" />
                <img class="absolute bottom-0 right-0" src="saturn-assets/images/team/light-orange.png" alt="" />
                <div class="relative container px-4 mx-auto">
                    <div class="max-w-md md:max-w-2xl mx-auto xl:max-w-7xl">
                        <div class="flex flex-wrap -mx-4 mb-18 items-center mb-12">
                            <div class="w-full px-8 lg:w-2/3 xl:w-1/2 mb-12 lg:mb-0">
                                <div>
                                    <h1 class="font-heading mb-4 logo text-5xl xs:text-5xl md:text-6xl font-bold text-gray-900">
                                        <span>Team of Experts</span>
                                    </h1>
                                </div>
                            </div>
                            <div class="w-full lg:w-1/3 xl:w-1/2 px-4">
                                <div class="max-w-sm lg:ml-auto">
                                    <p class="text-lg text-gray-500">We are a team of 80+ who are passionate about making your business efficient.</p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap -mx-12 -mb-12">

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Muhammad Ramish</h5>
                                        <span class="text-sm text-gray-500">Senior APEX Oracle Developer</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Muhammad Athar Raza</h5>
                                        <span class="text-sm text-gray-500">Full Stack Web Engineer</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full md:w-1/2 xl:w-1/4 px-4 mb-12">
                                <div class="max-w-x md:max-w-none mx-auto">
                                    <div class="flex flex-col items-center">
                                        <img class="block h-48 w-48 rounded-full" src={Wall} alt="" />
                                        <div class="inline-flex -mt-6 mb-5 items-center justify-center py-1 px-5 bg-white border border-slate-200 rounded-full">
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                            <a class="inline-block mr-3 p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-duotone fa-solid fa-envelope"></i>
                                            </a>
                                            <a class="inline-block p-1 hover:bg-orange-100 rounded-md" href="#">
                                                <i class="fa-brands fa-whatsapp"></i>
                                            </a>
                                        </div>
                                        <h5 class="text-2xl font-bold">Owais Majeed</h5>
                                        <span class="text-sm text-gray-500">Founder, CEO</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="mt-20 text-center">
                            <a class="relative group inline-block py-4 px-6 text-blue-50 font-semibold bg-blue-800 rounded-full overflow-hidden" href="#">
                                <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                                <div class="relative flex items-center justify-center">
                                    <span class="mr-4">See Open Positions</span>
                                    <span>
                                        <svg width="8" height="12" viewbox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Team
