import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from 'react-router-dom'
function ConsultPopup({ isVisible, onClose }) {
    const modalRef = useRef(null);

    useEffect(() => {
        if (isVisible) {
            gsap.fromTo(
                modalRef.current,
                { opacity: 0, scale: 0.8 }, // Start state
                { opacity: 1, scale: 1, duration: 0.5, ease: "power3.out" } // End state
            );
        } else {
            gsap.to(modalRef.current, {
                opacity: 0,
                scale: 0.8,
                duration: 0.5,
                ease: "power3.in",
                onComplete: onClose, // Ensure the modal is removed after the animation
            });
        }
    }, [isVisible, onClose]);

    if (!isVisible) return null;

    return (
        <div className='fixed inset-0 bg-gray-600 z-[99] bg-opacity-75 backdrop-blur-sm flex justify-center items-center'>
            <div ref={modalRef} className="bg-white border p-8 w-full max-w-[60rem] mx-auto border-gray-200 rounded-xl shadow-sm max-h-[470px] h-full pointer-events-auto">

                <div className="flex flex-col space-y-4 w-full">
                    <div className="flex items-start justify-between">
                        <div class="flex-1 p-2 logo">
                            <h2 class="mb-2 font-semibold text-black text-3xl">Need Consultation?</h2>
                            <p class="text-coolGray-500 font-medium">Select a consultation type and consult with our expert team to get personalized advice tailored to your needs.</p>
                        </div>
                        <button onClick={() => onClose()}>
                            <i class="fa-light fa-circle-xmark"></i>
                        </button>
                    </div>

                    <div className="flex items-center justify-center space-x-4">

                        <div className="border p-8 flex flex-col min-h-[300px] rounded-xl h-full w-full cardBG">
                            <div className="flex flex-col flex-1">
                                <h1 className="text-3xl text-white font-semibold tracking-wide">Shared</h1>
                                <h1 className="text-6xl text-white font-bold tracking-wide">Consultation</h1>
                            </div>

                            <div className="w-full">
                                <Link to='/services/consultation' class="relative group inline-block  xs:w-auto py-4 px-6 text-blue-900 hover:text-white font-semibold bg-blue-50 rounded-md overflow-hidden transition duration-500">
                                    <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                                    <div class="relative flex items-center justify-center">
                                        <span class="mr-4">Book it Now</span>
                                        <span>
                                            <svg width="13" height="13" viewbox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </Link>
                            </div>

                        </div>

                        <div className="border p-8 flex flex-col min-h-[300px] rounded-xl h-full w-full cardBG">
                            <div className="flex flex-col flex-1">
                                <h1 className="text-3xl text-white font-semibold tracking-wide">Dedicated</h1>
                                <h1 className="text-6xl text-white font-bold tracking-wide">Consultation</h1>
                            </div>

                            <div className="w-full">
                                <Link to='/services/consultation' class="relative group inline-block  xs:w-auto py-4 px-6 text-blue-900 hover:text-white font-semibold bg-blue-50 rounded-md overflow-hidden transition duration-500">
                                    <div class="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-105 transition duration-500"></div>
                                    <div class="relative flex items-center justify-center">
                                        <span class="mr-4">Book it Now</span>
                                        <span>
                                            <svg width="13" height="13" viewbox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </Link>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default ConsultPopup;
