import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import star from '../../assets/star-circle.svg';
import wall from '../../assets/wall.png'

const testimonials = [
    {
        text: "Saturn's team demonstrated exceptional skills and delivered top-notch results. Highly recommended!",
        name: "Alex Johnson",
        position: "CTO of Tech Corp",
        image: wall,
    },
    {
        text: "Saturn's team demonstrated exceptional skills and delivered top-notch results. Highly recommended!",
        name: "Alex Johnson",
        position: "CTO of Tech Corp",
        image: wall,
    },
    {
        text: "Saturn's team demonstrated exceptional skills and delivered top-notch results. Highly recommended!",
        name: "Alex Johnson",
        position: "CTO of Tech Corp",
        image: wall,
    },
    {
        text: "Saturn's team demonstrated exceptional skills and delivered top-notch results. Highly recommended!",
        name: "Alex Johnson",
        position: "CTO of Tech Corp",
        image: wall,
    },
    {
        text: "Saturn's team demonstrated exceptional skills and delivered top-notch results. Highly recommended!",
        name: "Alex Johnson",
        position: "CTO of Tech Corp",
        image: wall,
    },
];

function Testimonial() {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const totalCards = testimonials.length;
        const visibleCards = 3;
        const cardWidth = container.offsetWidth / visibleCards;

        // Duplicate the cards for seamless scrolling
        const duplicatedTestimonials = [...testimonials, ...testimonials];

        gsap.to(container, {
            x: `-${cardWidth * testimonials.length}px`,
            duration: 40, // Slower speed for scrolling
            ease: "linear",
            repeat: -1,
        });

        return () => {
            // Cleanup any GSAP animations
            gsap.killTweensOf(container);
        };
    }, []);

    return (
        <section className="relative py-20 lg:pt-32 lg:pb-36 overflow-hidden">
            <img
                className="absolute top-0 right-0 mt-6 -mr-24 md:-mr-0"
                src={star}
                alt=""
            />
            <div className="relative container px-4 mx-auto">
                <div className="max-w-7xl px-8 mx-auto">
                    <div className="flex flex-wrap -mx-4 items-center mb-16">
                        <div className="w-full lg:w-2/3 px-4 mb-12 lg:mb-0">
                            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-blue-900 bg-blue-50 rounded-full">
                                TESTIMONIALS
                            </span>
                            <h1 className="font-heading text-6xl font-bold text-gray-900 mb-4">
                                <span>What our clients said</span>
                            </h1>
                            <p className="text-gray-500">
                                Risus viverra justo sagittis vestibulum metus.
                            </p>
                        </div>
                        <div className="w-full lg:w-1/3 px-4">
                            <div className="flex items-center justify-end">
                                {/* Navigation buttons can be added here if needed */}
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-hidden">
                        <div
                            className="flex"
                            ref={containerRef}
                        >
                            {testimonials.concat(testimonials).map((testimonial, index) => (
                                <div key={index} className="flex-shrink-0 w-full lg:w-1/3 p-5">
                                    <div className="flex flex-col justify-between h-full max-w-md mx-auto lg:max-w-none bg-white border border-slate-200 rounded-lg shadow-lg overflow-hidden">
                                        <div className="p-6">
                                            <div className="flex mb-4 items-center text-yellow-500 text-2xl">
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                                <i className="fa-solid fa-stars"></i>
                                            </div>
                                            <p className="text-lg text-gray-900">
                                                {testimonial.text}
                                            </p>
                                        </div>
                                        <div className="px-8 py-4 bg-blue-800">
                                            <div className="flex items-center">
                                                <img
                                                    className="block w-10 h-10 rounded-full"
                                                    src={testimonial.image}
                                                    alt={testimonial.name}
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl text-white font-semibold leading-none">
                                                        {testimonial.name}
                                                    </span>
                                                    <span className="block text-sm text-blue-200">
                                                        {testimonial.position}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="absolute inset-0 pointer-events-none">
                            <div className="absolute top-0 left-0 w-1/6 h-full bg-gradient-to-r from-white via-transparent"></div>
                            <div className="absolute top-0 right-0 w-1/6 h-full bg-gradient-to-l from-white via-transparent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
